<template>
  <v-container id="page-edrColleage" fluid>
    <v-row>
      <v-col cols="12">
        <v-toolbar color="primary" dark>
          <v-toolbar-title>
            <v-icon class="mr-2">mdi-console</v-icon
            >{{ $t("nav.deployEdrApp") }}
          </v-toolbar-title>
          <v-spacer />
          <v-text-field
            v-model="cmd"
            hide-details
            placeholder="command line"
            filled
            readonly
            rounded
            dense
            prepend-inner-icon="mdi-console-line"
          />
        </v-toolbar>
      </v-col>


      <v-col
        v-for="(item, index) in dataList"
        :key="`deploy-edr-${index}`"
        cols="12"
        class="py-0 my-0"
      >
        <v-card elevation="1">
          <v-card-text>
            <p class="wee-text-head">
              {{ item.name_th }}
              <a
                :href="item.java_ipaddress"
                target="_blank"
                class="wee-text-black wee-link-no-line"
                >{{ item.java_ipaddress }}</a
              >
            </p>
            <v-list
              v-if="item.logs.length > 0"
              height="120"
              class="blue-grey darken-4"
              dense
              style="overflow: scroll"
            >
              <v-list-item
                v-for="(l, lindex) in item.logs"
                :key="`deploy-edr-logs-${index}-${lindex}`"
              >
                <v-list-item-content>
                  <v-list-item-subtitle class="white--text" v-text="l" />
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-row>
              <v-col
                v-for="(s, i) in services"
                :key="`deploy-edr-service-${index}-${i}`"
                cols="12"
                sm="3"
                class="py-0 my-0"
              >
                <v-subheader>{{ s.name }}</v-subheader>
                <template v-if="s.id !== 4">
                  <v-chip
                    class="mr-2 py-3"
                    :disabled="processing"
                    title="Restart"
                    @click="restart(index, s.id)"
                  >
                    <v-icon icon color="blue" class="mr-2">mdi-reload</v-icon> Restart
                  </v-chip>

                </template>
                <template v-else>
                  <v-chip
                    class="mr-2 py-3"
                    small
                    :disabled="processing"
                    @click="checkServerStatus(index)"
                  >
                    <v-icon text color="green">mdi-check</v-icon>
                    Check Service status
                  </v-chip>
                  <br>

                  <v-chip
                    class="ma-2"
                    :color="item.serverStatus.javaStatus ? 'success' : 'error'"
                  >
                    Java
                  </v-chip>
                  <v-chip
                    class="ma-2"
                    :color="item.serverStatus.phpStatus ? 'success' : 'error'"
                  >
                    Php
                  </v-chip>
                  <v-chip
                    class="ma-2"
                    :color="
                      item.serverStatus.socketStatus ? 'success' : 'error'
                    "
                  >
                    WebSockets
                  </v-chip>
                </template>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <wee-confirm ref="weeConfirmRef" />
    <wee-toast ref="weeToastRef" />
    <base-wee-loader v-model="processing" />
  </v-container>
</template>
<script>
// import { vLog } from "@/plugins/util";
// service
import EdrColleageService from "@/api/EdrColleageService";
import DevService from "@/api/DevService";
import useBase from "@/composition/UseBase";
import { onMounted, ref, computed } from "@vue/composition-api";
export default {
  components: {
    WeeConfirm: () => import("@/components/WeeConfirm"),
    WeeToast: () => import("@/components/WeeToast")
  },
  setup(props, { refs, root }) {
    const edrColleageService = new EdrColleageService();
    const devService = new DevService();
    const cmd = ref("whoami");
    const edrWarName = ref("");
    const collegeList = ref([]);
    const processing = ref(false);

    const services = ref([
      { id: 1, name: "Apache tomcat" },
      // { id: 2, name: "Mysql" },
      // { id: 3, name: "Apache2" },
      { id: 4, name: "Services status" }
    ]);

    const { toast } = useBase(refs, root);
    onMounted(() => {
      loadColege();
    });
    const loadColege = async () => {
      processing.value = true;
      const {
        response,
        error
      } = await edrColleageService.findAllProductionSite();
      processing.value = false;
      if (error) {
        toast(false, error);
        return;
      }
      toast(response.status, response.message);
      if (response && response.dataList) {
        const list = response.dataList;
        collegeList.value = list.map(item => {
          const container = {
            ...item,
            logs: [],
            serverStatus: {
              javaStatus: false,
              phpStatus: false,
              socketStatus: false
            }
          };
          return container;
        });
      }
    };
    const getCollgeByIndex = i => collegeList.value[i];
    // const runCmd = async index => {
    //   processing.value = true;
    //   const college = getCollgeByIndex(index);
    //   const { response, error } = await devService.excuteCmd(
    //     { cmd: cmd.value },
    //     college
    //   );
    //   processing.value = false;
    //   vLog(response, "runCmd");
    //   manageToast(error, response);
    //   manageLogs(index, response, college);
    // };

    const restart = async (index, serviceId) => {
      processing.value = true;
      const college = getCollgeByIndex(index);
      const { response, error } = await devService.cmdServiceRestart(
        { serviceId: serviceId },
        college
      );
      processing.value = false;
      manageToast(error, response);
      manageLogs(index, response);
    };
    const stop = async (index, serviceId) => {
      processing.value = true;
      const college = getCollgeByIndex(index);
      const { response, error } = await devService.cmdServiceStop(
        { serviceId: serviceId },
        college
      );
      processing.value = false;
      manageToast(error, response);
      manageLogs(index, response);
    };
    const start = async (index, serviceId) => {
      processing.value = true;
      const college = getCollgeByIndex(index);
      const { response, error } = await devService.cmdServiceStart(
        { serviceId: serviceId },
        college
      );
      processing.value = false;
      manageToast(error, response);
      manageLogs(index, response);
    };
    const checkServerStatus = async index => {
      processing.value = true;
      const college = getCollgeByIndex(index);
      const { response, error } = await devService.checkServerStatus(college);
      processing.value = false;
      manageToast(error, response);
      if (response) {
        collegeList.value[index] = { ...college, serverStatus: response };
      }
    };
    const deployJavaAll = async () => {
      processing.value = true;
      const list = collegeList.value;
      for (let index = 0; index < list.length; index++) {
        await deployJava(index, false);
      }
      processing.value = false;
    };
    const deployPhpAll = async () => {
      processing.value = true;
      const list = collegeList.value;
      for (let index = 0; index < list.length; index++) {
        await deployPhp(index, false);
      }
      processing.value = false;
    };
    const reloadSocketTrackingDashboardAll = async () => {
      processing.value = true;
      const list = collegeList.value;
      for (let index = 0; index < list.length; index++) {
        await reloadSocketTrackingDashboard(index, false);
      }
      processing.value = false;
    };
    const openNewTab = href => {
      Object.assign(document.createElement("a"), {
        target: "_blank",
        href
      }).click();
    };
    const deployJava = async (index, showLoading) => {
      if (showLoading) {
        processing.value = true;
      }
      const college = getCollgeByIndex(index);
      if (!college.deploy_java) {
        return new Promise(resolve => {
          resolve(false);
        });
      }
      const { response, error } = await devService.cmdDeployEdrApp(
        { edrWarName: edrWarName },
        college
      );
      return new Promise(resolve => {
        if (showLoading) {
          processing.value = false;
        }
        openNewTab(college.java_ipaddress);
        manageToast(error, response);
        manageLogs(index, response);
        resolve(true);
      });
    };

    const deployPhp = async (index, showLoading) => {
      if (showLoading) {
        processing.value = true;
      }

      const college = getCollgeByIndex(index);
      if (!college.deploy_php) {
         return new Promise(resolve => {
          resolve(false);
        });
      }
      const { response, error } = await devService.cmdDeployEdrPhpApp(
        { edrWarName: edrWarName },
        college
      );
      return new Promise(resolve => {
        if (showLoading) {
          processing.value = false;
        }
        manageToast(error, response);
        manageLogs(index, response);
        resolve(true);
      });
    };
    const updateAppPermissionAll = async () => {
      processing.value = true;
      const list = collegeList.value;
      for (let index = 0; index < list.length; index++) {
        await updateAppPermission(index, false);
      }
      processing.value = false;
    };
    const updateAppPermission = async (index, showLoading) => {
      if (showLoading) {
        processing.value = true;
      }

      const college = getCollgeByIndex(index);
      if (!college) {
         return new Promise(resolve => {
          resolve(false);
        });
      }
      const { response, error } = await devService.cmdUpdatePermissionApi(
        college
      );
      return new Promise(resolve => {
        if (showLoading) {
          processing.value = false;
        }
        manageToast(error, response);
        manageLogs(index, response);
        resolve(true);
      });
    };

    // migrate edr data
    const devMigrateEdrData = async () => {
      refs.weeConfirmRef
        .open("Migrate data", "Confirm to migrate data ?", { color: "info" })
        .then(confirm => {
          if (confirm) {
            devMigrateEdrDataConfirm();
          }
        });
    };
    const devMigrateEdrDataConfirm = async () => {
      processing.value = true;
      const list = collegeList.value;
      for (let index = 0; index < list.length; index++) {
        await devMigrateEdrDataProcess(index, false);
      }
      processing.value = false;
    };
    const devMigrateEdrDataProcess = async (index, showLoading) => {
      if (showLoading) {
        processing.value = true;
      }

      const college = getCollgeByIndex(index);
      if (!college) {
         return new Promise(resolve => {
          resolve(false);
        });
      }
      const { response, error } = await devService.devMigrateEdrData(college);
      // console.log("devMigrateEdrData > "+college.college_code, response);
      return new Promise(resolve => {
        if (showLoading) {
          processing.value = false;
        }
        manageToast(error, response);
        manageLogs(index, response);
        resolve(true);
      });
    };
    const devRenewSSLProcess = async (index, showLoading) => {
      if (showLoading) {
        processing.value = true;
      }
      const college = getCollgeByIndex(index);
      if (!college) {
         return new Promise(resolve => {
          resolve(false);
        });
      }
      const { response, error } = await devService.devRenewSSLProcess(college);
      return new Promise(resolve => {
        if (showLoading) {
          processing.value = false;
        }
        manageToast(error, response);
        manageLogs(index, response);
        resolve(true);
      });
    };

    const reloadSocketTrackingDashboard = async (index, showLoading) => {
      if (showLoading) {
        processing.value = true;
      }
      const college = getCollgeByIndex(index);
      if (!college.deploy_php) {
         return new Promise(resolve => {
          resolve(false);
        });
      }
      const {
        response,
        error
      } = await devService.broadcastReloadSocketRealtime({}, college);
      if (showLoading) {
        processing.value = false;
      }
      return new Promise(resolve => {
        manageToast(error, response);
        resolve(true);
      });
    };

    const manageToast = (error, response) => {
      if (error) {
        toast(false, error);
        return;
      } else {
        toast(response.server_status, response.server_msg);
      }
    };
    const manageLogs = (index, response) => {
      if (response && response.output) {
        // const newLogs = currentCollege.logs.concat(response.output);

        let currentCollege = collegeList.value[index];
        currentCollege.logs = response.output;
        collegeList.value[index] = currentCollege;
        // collegeList.value[index] = Object.assign({}, { ...currentCollege, logs: newLogs });
      }
    };
    const clearLogs = index => {
      let currentCollege = collegeList.value[index];
      currentCollege.logs = [];
      collegeList.value[index] = currentCollege;
    };

    const dataList = computed(() => collegeList.value);

    return {
      processing,
      // collegeList,
      dataList,
      services,
      cmd,
      //   runCmd,
      restart,
      stop,
      start,
      checkServerStatus,
      deployJava,
      deployJavaAll,
      deployPhp,
      deployPhpAll,
      clearLogs,
      reloadSocketTrackingDashboard,
      reloadSocketTrackingDashboardAll,
      toast,
      updateAppPermissionAll,
      devMigrateEdrData,
      devMigrateEdrDataProcess,
      devRenewSSLProcess
    };
  }
};
</script>
